import { useEffect } from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { ChakraProvider } from '@chakra-ui/react';
import cookies from 'js-cookie';

import env, { isProd } from 'config/env';
import theme from 'styles/theme';

import 'regenerator-runtime/runtime';
import 'focus-visible/dist/focus-visible';
import './analytics/charts/rangeSelector.css';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  useEffect(() => {
    const checkForNextLink = () => {
      const nextLink = router.query.next as string;
      const token = cookies.get('at');

      if (nextLink && token) {
        const url = decodeURIComponent(nextLink);

        if (url) {
          router.replace(url);
        }
      }
    };

    checkForNextLink();
  }, [router]);

  // Persistent Layout as per https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/
  const getLayout = Component['getLayout'] || ((page) => page);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      {isProd && (
        <>
          <Script id="hotjar">
            {`(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:"${env.HOTJAR.HJID}",hjsv:${env.HOTJAR.HJSV}};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
          </Script>

          {/* Global Site Tag (gtag.js) - Google Analytics */}
          <Script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-6LKK886MDM"
          />
          <Script
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-6LKK886MDM');
              `,
            }}
          />
        </>
      )}

      <ChakraProvider theme={theme}>
        {getLayout(<Component {...pageProps} />)}
      </ChakraProvider>
    </>
  );
}

export default MyApp;
