interface EnvVars {
  [key: string]: string | number | boolean;
}

const env = {
  CUSTOM_NODE_ENV: process.env.NEXT_PUBLIC_CUSTOM_NODE_ENV || 'dev',
  GOOGLE_PLACES_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY,
  API_URL: process.env.NEXT_PUBLIC_API_URL,
  HOTJAR: {
    HJID: process.env.NEXT_PUBLIC_HJID,
    HJSV: process.env.NEXT_PUBLIC_HJSV,
  },
  XKITDOMAIN: process.env.NEXT_PUBLIC_XKITDOMAIN,
  // add new environment variables above here ...
};

export const STRIPE_PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;

// development environment
export const isDev: boolean = env.CUSTOM_NODE_ENV === 'dev';

// staging environment
export const isStaging: boolean = env.CUSTOM_NODE_ENV === 'staging';

// production environment
export const isProd: boolean = env.CUSTOM_NODE_ENV === 'prod';

export default env;
