import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

import { breakpoints } from './breakpoints';

const theme = extendTheme({
  config: {
    cssVarPrefix: 'scope',
  },
  fonts: {
    heading: '"Open Sans", sans-serif',
    body: '"Open Sans", sans-serif',
  },
  colors: {
    primary: {
      50: '#ECF0FC',
      100: '#D9E2F8',
      200: '#7B99E8',
      300: '#688AE5',
      400: '#2B5FEF',
      500: '#557CE1',
      600: '#426DDE',
    },
    blue: {
      500: '#426DDE',
    },
    secondary: {
      50: '#E7ECF8',
      100: '#CfD8F1',
      200: '#6E8AD4',
      300: '#5677CD',
      400: '#3D63C6',
      500: '#2550BF',
      600: '#0D3CB8',
    },
    ui: {
      black: {
        bg: '#2D2C2D',
        medium: '#2D2C2D',
        dark: '#222537',
      },
      white: '#FFFFFF',
      blue: {
        primary: '#426DDE',
        secondary: '#0D3CB8',
      },
      gray: {
        bg: '#F9F9F9',
        light: '#E5E5E5',
        medium: '#9C9C9C',
        dark: '#717171',
      },
      orange: '#FEC069',
      purple: '#AF8FE4',
      green: '#76BF3E',
      // Vendor purple
      vendor: '#AF8FE4',
      // Customer orange
      customer: '#FEC069',
      // Expert green
      expert: '#76BF3E',
      red: '#FF0000',
    },
  },
  fontSizes: {
    xs: '12.8px',
    sm: '16px',
    md: '20px',
    lg: '25px',
    xl: '31.25px',
    '2xl': '39.06px',
    '3xl': '48.83px',
  },
  fontWeights: {
    normal: 400,
    medium: 600,
    bold: 700,
  },
  lineHeights: {
    xs: '19.2px',
    sm: '24px',
    md: '30px',
    lg: '37.5px',
    xl: '46.88px',
    '2xl': '58.59px',
    '3xl': '73.25px',
  },
  breakpoints: createBreakpoints({
    sm: `${breakpoints.sm}px`,
    md: `${breakpoints.md}px`,
    lg: `${breakpoints.lg}px`,
    xl: `${breakpoints.xl}px`,
  }),
  sizes: {
    17: '72px',
  },
  components: {
    Modal: {
      // Feel free to change these.
      sizes: {
        xs: {
          dialog: {
            maxW: '30%',
          },
        },
        sm: {
          dialog: {
            maxW: '40%',
          },
        },
        md: {
          dialog: {
            maxW: '50%',
          },
        },
        lg: {
          dialog: {
            maxW: '60%',
          },
        },
        xl: {
          dialog: {
            maxW: '70%',
          },
        },
        full: {
          dialog: {
            maxW: '90%',
            h: '95%',
          },
        },
      },
    },
    Radio: {
      parts: ['control'],
      baseStyle: {
        control: {
          width: 6,
          minHeight: 6,
          '&&': {
            '::before': {
              width: '70%',
              height: '70%',
            },
          },
        },
      },
      variants: {
        base: {},
        secondary: {
          control: {
            width: 4,
            minHeight: 4,
          },
        },
      },
    },
    Tooltip: {
      baseStyle: {
        padding: '4',
        fontSize: 'xs',
        fontWeight: 'bold',
        background: 'white',
        color: 'ui.black.bg',
        borderRadius: '5px',
      },
    },
  },
  styles: {
    global: {
      'body::before': {
        content: '""',
        display: 'block',
        position: 'fixed',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        background: '#fff',
        zIndex: '99999',
      },
      '&.render': {
        'body::before': {
          display: 'none',
        },
      },
      /*
        This will hide the focus indicator if the element receives focus via the mouse,
        but it will still show up on keyboard focus.
        https://www.npmjs.com/package/focus-visible
      */
      '.js-focus-visible :focus:not([data-focus-visible-added])': {
        outline: 'none',
        boxShadow: 'none',
      },
      '.chakra-popover__popper': {
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
      },
      '.DayPickerInput': {
        width: '100%',
      },
      '.input-range__track': {
        bg: 'ui.gray.light',
      },
      '.input-range__track--active': {
        bg: 'ui.blue.primary',
      },
      '.input-range__slider': {
        bg: 'ui.blue.primary',
        borderColor: 'ui.blue.primary',
      },
    },
  },
});

export default theme;
