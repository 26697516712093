// Bootstrap sizes
export const breakpoints = {
  sm: 575.98,
  md: 767.98,
  lg: 991.98,
  xl: 1199.98,
};

export const mediaQuery = {
  min: {
    sm: `(min-width: ${breakpoints.sm + 0.02}px)`,
    md: `(min-width: ${breakpoints.md + 0.02}px)`,
    lg: `(min-width: ${breakpoints.lg + 0.02}px)`,
    xl: `(min-width: ${breakpoints.xl + 0.02}px)`,
  },
  max: {
    sm: `(max-width: ${breakpoints.sm}px)`,
    md: `(max-width: ${breakpoints.md}px)`,
    lg: `(max-width: ${breakpoints.lg}px)`,
    xl: `(max-width: ${breakpoints.xl}px)`,
  },
};

export const screenWidthLess = (key: string) => `@media ${mediaQuery.max[key]}`;
export const screenWidthLessPx = (size: string) =>
  `@media (max-width: ${size}px)`;

export const screenWidthMore = (key: string) => `@media ${mediaQuery.min[key]}`;
export const screenWidthMorePx = (size: string) =>
  `@media (min-width: ${size}px)`;

export const screenWidthBetween = (keyMin: string, keyMax: string) =>
  `@media ${mediaQuery.min[keyMin]} and ${mediaQuery.max[keyMax]}`;
export const screenWidthBetweenInPx = (min: number, max: number) =>
  `@media (min-width: ${min}px) and (max-width: ${max}px)`;
